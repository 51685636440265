import { HTTPResponseError } from "./http_response_error";

export type FunctionsErrorDetail = {
  code: "lint_failed" | "compilation_failed" | "unknown";
  message: string;
  errors: string[];
};

function isFunctionsValidationError(error: null | Error | FunctionsValidationError): error is FunctionsValidationError {
  return error instanceof FunctionsValidationError;
}

export function parseFunctionError(error: null | Error | FunctionsValidationError) {
  if (!isFunctionsValidationError(error)) return;

  return error.detail;
}
export class FunctionsValidationError extends Error {
  public readonly status: number;

  public readonly detail: FunctionsErrorDetail;

  public readonly url: string;

  constructor(url: string, status: number, detail: FunctionsErrorDetail) {
    super("Function validation failed");
    Object.setPrototypeOf(this, FunctionsValidationError.prototype);

    this.status = status;
    this.detail = detail;
    this.url = url;
  }

  static async create(response: Response): Promise<FunctionsValidationError> {
    const { status } = response;

    if (HTTPResponseError.isJSONBody(response)) {
      return new FunctionsValidationError(response.url, status, await response.json());
    }

    return new FunctionsValidationError(response.url, status, {
      code: "unknown",
      message: await response.text(),
      errors: [],
    });
  }
}
