import { HTTPResponseError } from "./http_response_error";

export * from "./functions_validation_error";
export * from "./http_response_error";
export * from "./auth_error";
export * from "./unconfirmed_user_error";

const isHTTPError = (error: unknown): error is HTTPResponseError => error instanceof HTTPResponseError;

export const getMessageFromError = (error: unknown): string => {
  if (error instanceof Error) {
    if (isHTTPError(error)) {
      return error.detail.message;
    }

    return error.message;
  }

  return "";
};
