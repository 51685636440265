import { datadogRum, RumEvent, RumEventDomainContext } from "@datadog/browser-rum";
import { isProd } from "../../api/environment";
import {
  enrichRUMErrorEvent,
  renameRUMActionEvent,
  shouldDiscardRUMActionEvent,
  shouldDiscardRUMErrorEvent,
  shouldRUMBeActive,
} from "../../shared/utils/rum";
import { unmountHandler } from "../../shared/utils/unmountHandler";

export const beforeSendToDatadogCallback = (event: RumEvent, context: RumEventDomainContext): void | boolean => {
  if (event.type === "error") {
    enrichRUMErrorEvent(event, context);

    if (shouldDiscardRUMErrorEvent(event)) {
      return false;
    }
  }

  if (event.type === "action") {
    if (shouldDiscardRUMActionEvent(event, context)) {
      return false;
    }

    renameRUMActionEvent(event, context);
  }

  return true;
};

if (shouldRUMBeActive()) {
  const datadogEnv = isProd() ? "prod" : "preprod";

  datadogRum.init({
    clientToken: "pub766c4e00b33c3198d56cf91bdf5c63d2",
    applicationId: "0ad0196c-8960-4f26-af50-8be7d34f2d31",
    site: "datadoghq.com",
    service: "Terminal",
    env: datadogEnv,
    version: process.env["REACT_APP_VERSION"],
    sampleRate: 100,
    trackInteractions: true,
    trackSessionAcrossSubdomains: true,
    allowedTracingOrigins: ["https://tokens.prod.saas.stedi.com"],
    proxyUrl: "https://rum.stedi.com/dd",
    // As per https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#discard-a-rum-event
    // Please note that `if (error typeof XXX)` checks will not work here since the error is an error created by DataDog RUM tool.
    beforeSend: beforeSendToDatadogCallback,
  });
}

window.addEventListener("error", unmountHandler);
