export type AuthErrorCodes =
  | "AuthException"
  | "CodeMismatchException"
  | "InvalidUsernameOrPassword"
  | "UserNotConfirmedException"
  | "UserNotFoundException"
  | "UsernameExistsException"
  | "UserLambdaValidationException"
  | "PasswordResetRequired";

export class AuthError extends Error {
  public code: AuthErrorCodes;

  constructor(code: AuthErrorCodes, message?: string) {
    super(message ?? code);
    Object.setPrototypeOf(this, AuthError.prototype);

    this.code = code;
  }

  public isUnconfirmedException(): boolean {
    return this.code === "UserNotConfirmedException";
  }
}
