export interface ErrorDetail {
  type: "standard" | "unknown";
  message: string;
  code?: string;
  errors?: string[];
}

export const toErrorDetail = (response: any): ErrorDetail => {
  if (response.details) {
    return {
      type: "standard",
      message: response.details.message,
      code: response.details.code,
      errors: response.errors,
    };
  }
  if (response.message) {
    return { type: "standard", message: response.message, code: response.code, errors: response.errors };
  }
  return { type: "unknown", message: JSON.stringify(response), code: response.code };
};

export interface IHTTPResponseError extends Error {
  readonly status: number;
  readonly detail: ErrorDetail;
  readonly url: string;
  toDatadogContext(): {
    readonly status: number;
    readonly detail: ErrorDetail;
    readonly url: string;
  };
}

export class HTTPResponseError extends Error implements IHTTPResponseError {
  public readonly status: number;

  public readonly detail: ErrorDetail;

  public readonly url: string;

  public readonly response?: Response;

  constructor(message: string, url: string, status: number, detail: ErrorDetail, response?: Response) {
    super(message);
    Object.setPrototypeOf(this, HTTPResponseError.prototype);

    this.status = status;
    this.detail = detail;
    this.url = url;
    this.response = response;
  }

  static isJSONBody(response: Response): boolean {
    // TODO(adam): remove when transactions returns the correct content-type.
    if (response.url.includes("transactions")) {
      return false;
    }
    return response.headers.get("content-type")?.startsWith("application/json") || false;
  }

  static async create(response: Response): Promise<HTTPResponseError> {
    // The `statusText` can be an empty string.
    const statusTextMessage = response.statusText;
    const { status } = response;

    if (HTTPResponseError.isJSONBody(response)) {
      const errorDetail = toErrorDetail(await response.json());
      const errorMessage = statusTextMessage !== "" ? statusTextMessage : errorDetail.message;

      return new HTTPResponseError(errorMessage, response.url, status, errorDetail, response);
    }

    const responseText = await response.text();
    const errorMessage = statusTextMessage !== "" ? statusTextMessage : responseText;
    return new HTTPResponseError(
      errorMessage,
      response.url,
      status,
      {
        type: "standard",
        message: responseText,
      },
      response,
    );
  }

  toDatadogContext() {
    return {
      status: this.status,
      detail: this.detail,
      url: this.url,
    };
  }
}
