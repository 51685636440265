import * as React from "react";
import ReactDOM from "react-dom";
import { datadogRum } from "@datadog/browser-rum";
import { isAppUnmounted, shouldRUMBeActive } from "./rum";

export const unmountHandler = (event: ErrorEvent) => {
  const rootElement = document.getElementById("root");
  const unmountErrorName = "TERMINAL_UNMOUNTED";

  if (isAppUnmounted()) {
    ReactDOM.render(
      <div
        data-testid="stedi-error"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "32px",
          flexDirection: "column",
        }}
      >
        <h1 style={{ fontFamily: "Biotif, sans-serif", margin: 0, fontSize: "32px", fontWeight: 400 }}>
          Something went wrong
        </h1>
        <p
          style={{
            fontFamily: "Recursive, sans-serif",
            fontSize: "16px",
            fontWeight: 300,
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          Please refresh the page and try again
        </p>
        <button
          onClick={() => window.location.reload()}
          style={{
            fontSize: "14px",
            fontFamily: "Recursive, sans-serif",
            appearance: "none",
            border: "none",
            borderRadius: "3px",
            color: "#FFF",
            background: "rgb(50, 161, 123)",
            padding: "8px",
            cursor: "pointer",
          }}
          type="button"
        >
          Refresh
        </button>
      </div>,
      rootElement,
    );

    if (shouldRUMBeActive()) {
      datadogRum.addError(unmountErrorName, { description: event.message });
    }
  }
};
