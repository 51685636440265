export class UnconfirmedUserError extends Error {
  public email: string;

  public password: string;

  constructor(email: string, password: string, message?: string) {
    super(message ?? `user ${email} unconfirmed`);
    Object.setPrototypeOf(this, UnconfirmedUserError.prototype);
    this.email = email;
    this.password = password;
  }
}
