import {
  datadogRum,
  RumActionEvent,
  RumActionEventDomainContext,
  RumErrorEvent,
  RumErrorEventDomainContext,
  RumEventDomainContext,
} from "@datadog/browser-rum";
import { StediUser } from "../../api/authentication";
import { isPreprod, isProd } from "../../api/environment";
import { AuthError, HTTPResponseError, UnconfirmedUserError } from "../../api/errors";

declare global {
  interface Window {
    DD_RUM?: typeof datadogRum;
  }
}

export const shouldRUMBeActive = () => {
  if (process.env["REACT_APP_VERSION"] === undefined) {
    return false;
  }

  if (navigator.userAgent.includes("HeadlessChrome")) {
    return false;
  }

  return isProd() || isPreprod();
};

const invalidLoginErrorRegex = /Invalid email or password/i;
const ignoredErrorMessages = [
  /**
   * The `ResizeObserver loop limit exceeded` error in Chrome and
   * the `ResizeObserver loop completed with undelivered notifications` in Firefox can be safely ignored.
   * https://github.com/souporserious/react-measure/issues/104#issuecomment-438743015
   * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
   * https://stackoverflow.com/a/64257593
   */
  /ResizeObserver loop limit exceeded/i,
  /ResizeObserver loop completed with undelivered notifications/i,
  // FunctionsValidationErrors are to be expected and shouldn't be reported to DataDog
  /function validation failed/i,
  // Ignore AWS Auth errors for invalid logins
  invalidLoginErrorRegex,
];

export const shouldDiscardRUMErrorEvent = (event: RumErrorEvent) => {
  const errorMessage = event.error.message;
  /**
   * Indicates integrity issue. Errors should always have a message.
   */
  if (errorMessage === "") {
    return false;
  }

  const shouldDiscardAsGeneralError = ignoredErrorMessages.some((errorRegex) => errorRegex.test(errorMessage));

  const shouldDiscardAsHandledError = event.error.handling === "handled";

  return shouldDiscardAsGeneralError || shouldDiscardAsHandledError;
};

/**
 * To make sure all the properties of a given error are present in the event context, we have to perform the enrichment manually.
 *
 * https://docs.datadoghq.com/real_user_monitoring/guide/enrich-and-control-rum-data/?tab=event#collect-http-headers-from-a-fetch-response
 */
export const enrichRUMErrorEvent = (event: RumErrorEvent, context: RumEventDomainContext) => {
  const errorContext = context as RumErrorEventDomainContext;
  const errorContextError = errorContext.error;

  const isHTTPResponseError = errorContextError instanceof HTTPResponseError;
  if (!isHTTPResponseError) {
    return;
  }

  const currentEventContext = event.context ?? {};
  event.context = { ...currentEventContext, ...errorContextError.toDatadogContext() };
};

export const shouldDiscardRUMActionEvent = (event: RumActionEvent, context: RumEventDomainContext) => {
  /**
   * Custom actions should always be purposely emitted, and therefore
   * should not be dismissed based on the rules below
   */
  if (event.action.type === "custom") {
    return false;
  }

  /**
   * If the action name contains pieces of JSON - we reject it, as it is never a meaningful action taken by the user,
   * and has a potential to contain sensitive customer info.
   */
  const actionName = event.action.target?.name;
  if (actionName?.includes("{") || actionName?.includes("[")) {
    return true;
  }

  /**
   * If event does not have an associated pointer event target - we keep it,
   * as it's not associated with any particular element in DOM,
   * therefore it's not coming from any areas in DOM with sensitive content
   */
  const pointerEventTarget = getRUMActionEventTargetElement(context);
  if (!pointerEventTarget) {
    return false;
  }

  /**
   * All Monaco editors are considered to contain user content, therefore we should not leak its contents
   * via reporting interactions to the DD RUM.
   */
  const isWithinMonacoEditor = pointerEventTarget.closest(".monaco-editor") !== null;
  const containsMonacoEditor = pointerEventTarget.querySelector(".monaco-editor") !== null;

  if (isWithinMonacoEditor || containsMonacoEditor) {
    return true;
  }

  return false;
};

export const renameRUMActionEvent = (event: RumActionEvent, context: RumEventDomainContext) => {
  /**
   * Custom actions should always be purposely emitted, and therefore
   * should not be renamed based on the rules below
   */
  if (event.action.type === "custom") {
    return;
  }

  /**
   * If there was no action target provided, it means there's no name to update - skipping.
   */
  if (event.action.target === undefined) {
    return;
  }

  /**
   * If event does not have an associated pointer event target - we skip it, nothing to rename.
   */
  const pointerEventTarget = getRUMActionEventTargetElement(context);
  if (!pointerEventTarget) {
    return;
  }

  /**
   * If it's a click on the Modal overlay, it always means that the user is dismissing the modal - using the default
   * action name in these cases, to avoid automatic name detection which might contain sensitive information.
   */
  if (pointerEventTarget.matches(".ReactModal__Overlay") && event.action.target) {
    event.action.target.name = "Dismiss dialog";
    return;
  }
};

const getRUMActionEventTargetElement = (context: RumEventDomainContext): Element | null => {
  const { event: pointerEvent } = context as RumActionEventDomainContext;
  if (!pointerEvent || !pointerEvent.target) {
    return null;
  }

  /**
   * If the pointer event target is not a Element - we don't return it.
   */
  if (!isEventTargetAnElement(pointerEvent.target)) {
    return null;
  }

  return pointerEvent.target;
};

const isEventTargetAnElement = (eventTarget: EventTarget): eventTarget is Element => {
  return "closest" in eventTarget;
};

export const setUser = (user: Partial<StediUser> & { email: string }) => {
  if (!shouldRUMBeActive()) {
    return;
  }

  const { email, familyName, givenName, id } = user;

  const name = [givenName, familyName].filter(Boolean).join(" ");
  const external = !email.endsWith("@stedi.com");

  datadogRum.addRumGlobalContext("usr.id", id);
  datadogRum.addRumGlobalContext("usr.external", external);
  datadogRum.addRumGlobalContext("usr.email", email);

  datadogRum.setUser({ id, email, name, external });
};

export const setCurrentAccount = (accountId?: String) => {
  if (shouldRUMBeActive()) {
    datadogRum.addRumGlobalContext("account_id", accountId);
  }
};

export const isAppUnmounted = () => {
  return document.getElementById("root")?.children.length === 0;
};

export const captureError = (e: unknown, tags?: Record<string, string>): void => {
  if (!shouldRUMBeActive()) {
    return;
  }

  sanitizePasswordTags(tags);

  if (e instanceof UnconfirmedUserError) {
    return datadogRum.addError(e, {
      name: "CognitoError",
      description: e.message,
      tags: { "auth.error": "UnconfirmedUserError", ...tags },
    });
  }

  if (e instanceof AuthError) {
    // Filter out invalid username or password errors
    if (invalidLoginErrorRegex.test(e.message)) {
      return;
    }

    return datadogRum.addError(e, {
      name: "CognitoError",
      description: e.message,
      tags: { "auth.error": e.code, ...tags },
    });
  }

  if (e instanceof HTTPResponseError) {
    return datadogRum.addError(e, {
      name: "HTTPResponseError",
      description: e.detail.message,
      tags: {
        "http.error.status": e.status.toString(),
        "http.error.url": e.url,
        ...tags,
      },
    });
  }
  if (e instanceof Error) {
    if (e.message === "No current user") {
      return datadogRum.addError({
        name: "CognitoError",
        description: e.message,
        tags: { "auth.error": "NoCurrentUser", ...tags },
      });
    }
  }

  datadogRum.addError(e, { tags });
};

const sanitizePasswordTags = (tags: Record<string, string> = {}) => {
  Object.keys(tags).forEach((key) => {
    if (key.toLowerCase().includes("password")) {
      tags[key] = "REDACTED";
    }
  });
};
